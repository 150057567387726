var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"HeaderCard_div"},[_c('el-card',{attrs:{"shadow":"always"}},[(_vm.isCoustomPageToRolePage)?_c('div',{staticClass:"rolePageHeaderCardStyle"},[_c('div',{staticClass:"rolePageHeaderCardStyle-left"},[_c('div',[_vm._v(" "+_vm._s(_vm.rolePageObjectData ? _vm.rolePageObjectData.homePageName : "")+" ")]),_c('el-popover',{attrs:{"placement":"bottom","width":"265","trigger":"click","offset":80},model:{value:(_vm.isPopoverShow),callback:function ($$v) {_vm.isPopoverShow=$$v},expression:"isPopoverShow"}},[_c('div',{staticClass:"operation-area"},[_c('div',{staticClass:"operation-edit-name"},[_vm._v(" "+_vm._s(_vm.$t("vue_label_homepage_editprofilepage"))+" ")]),_c('el-form',{ref:"rolePageHeaderEditPageNameFormRef",attrs:{"model":_vm.form,"size":"mini","label-width":"65px","label-position":"left"}},[_c('el-form-item',{attrs:{"label":_vm.$t('label_chatter_name'),"rules":{
                  required: true,
                  message: _vm.$t('vue_label_notice_homepage_pleaceentername'),
                  trigger: ['blur', 'change'],
                },"prop":"name"}},[_c('el-input',{attrs:{"size":"mini"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{staticClass:"operation-menu"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.popoverCancleEvent}},[_vm._v(" "+_vm._s(_vm.$t("button_partner_cancel")))]),_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.saveRolePageHeaderEditPageNameFormEvent(
                      'rolePageHeaderEditPageNameFormRef'
                    )}}},[_vm._v(" "+_vm._s(_vm.$t("label.save")))])],1)],1)],1),_c('i',{staticClass:"el-icon-edit rolePageHeaderCard-edit-style",attrs:{"slot":"reference"},on:{"click":_vm.HeaderBarEditRolePageNameEvent},slot:"reference"})])],1),(!_vm.isEditStatus)?_c('div',{staticClass:"rolePageHeaderCardStyle-right"},[_c('i',{ref:"loadimgbox",staticClass:"el-icon-refresh-right rolePageHeaderCardStyle-right-refresh",on:{"click":_vm.refreshEvent}}),_c('el-button',{attrs:{"size":"mini"},nativeOn:{"click":function($event){return _vm.rolePageHeaderCardExitEvent.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("button_partner_back"))+" ")]),_c('el-button',{attrs:{"size":"mini"},nativeOn:{"click":function($event){return _vm.rolePageHeaderCardDeleteEvent.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("component_setup_tabs_label_delete")))]),_c('el-button',{attrs:{"size":"mini"},nativeOn:{"click":function($event){return _vm.rolePageHeaderCardReSortEvent.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("c568"))+" ")]),_c('el-button',{attrs:{"size":"mini","type":"primary"},nativeOn:{"click":function($event){return _vm.rolePageHeaderCardAddCpnEvent.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("vue_label_norm_addcomponent")))])],1):_vm._e(),(_vm.isEditStatus)?_c('div',{staticClass:"rolePageHeaderCardStyle-right"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.cancelSwitchs}},[_vm._v(" "+_vm._s(_vm.$t("button_partner_cancel")))]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.saveEvent}},[_vm._v(" "+_vm._s(_vm.$t("label.save")))])],1):_vm._e()]):_vm._e(),(!_vm.isCoustomPageToRolePage && _vm.realName)?_c('div',{staticClass:"head-menu"},[_c('div',{staticClass:"head-left-menu"},[_c('div',[_vm._v(_vm._s(this.$store.state.userInfoObj.realName))]),_c('div',[_vm._v(_vm._s(this.$store.state.userInfoObj.orgName))]),_c('div',[_vm._v(_vm._s(_vm.currentTime))]),(
            ['exorg20226495816test', 'exorg20224311927test'].indexOf(
              _vm.$CCDK.CCUser.getUserInfo().orgId
            ) == -1
          )?_c('div',[_vm._v(" （"+_vm._s(_vm.$t("label.currency"))+"："+_vm._s(_vm.$store.getters.getCurrencyCode)+"） ")]):_vm._e()]),_c('div',{staticClass:"head-right-menu"},[(!_vm.isEditStatus)?_c('div',{staticClass:"head-right-menu_div"},[_c('svg',{directives:[{name:"show",rawName:"v-show",value:(!_vm.changeBlueYes),expression:"!changeBlueYes"}],staticClass:"icon screenfullImg",attrs:{"aria-hidden":"true"},on:{"mouseover":_vm.overChange}},[_c('use',{attrs:{"href":"#icon-fullScreenWhite"}})]),_c('svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.changeBlueYes),expression:"changeBlueYes"}],staticClass:"icon screenfullImg",attrs:{"aria-hidden":"true"},on:{"click":_vm.screenFull,"mouseout":_vm.outChange}},[_c('use',{attrs:{"href":"#icon-fullScreenBlue"}})]),_c('i',{ref:"loadimgbox",staticClass:"el-icon-refresh-right refresh-class",on:{"click":_vm.refreshEvent}}),_c('el-dropdown',{staticClass:"dropdown-area",attrs:{"trigger":"click"}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" "+_vm._s(_vm.selectTitleName)+" "),_c('i',{staticClass:"el-icon-caret-bottom"})]),_c('el-dropdown-menu',{staticStyle:{"border":"1px solid #e1e1e1 !important"},attrs:{"slot":"dropdown","placement":"bottom-end"},slot:"dropdown"},[_vm._l((_vm.HomePageListData),function(item,index){return _c('el-dropdown-item',{key:index},[(item.type == 'standard')?_c('a',{class:{
                    'custom-menu-style': _vm.selectTitleName === item.name,
                  },attrs:{"href":_vm.clickToPageHref('standardPage'),"target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.clickToPage('standardPage')}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()])}),_c('el-dropdown-item',[_c('a',{class:{
                    'custom-menu-style':
                      _vm.selectTitleName === ("" + (_vm.$t('vue_label_homepage_owner'))),
                  },attrs:{"href":_vm.clickToPageHref('personalPage'),"target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.clickToPage('personalPage')}}},[_vm._v(" "+_vm._s(_vm.$t("vue_label_homepage_owner"))+" ")])]),(_vm.rolePageDropDownName)?_c('el-dropdown-item',[_c('a',{class:{
                    'custom-menu-style':
                      _vm.selectTitleName === _vm.rolePageDropDownName,
                  },attrs:{"href":_vm.clickToPageHref('rolePage'),"target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.clickToPage('rolePage')}}},[_vm._v(" "+_vm._s(_vm.rolePageDropDownName))])]):_vm._e(),(_vm.isAdmin && !_vm.Partnercloud)?_c('el-dropdown-item',[_c('a',{class:{
                    'custom-menu-style':
                      _vm.selectTitleName ===
                      _vm.$t('vue_label_homepage_custom_homepage_page'),
                  },attrs:{"href":_vm.clickToPageHref('customPage'),"target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.clickToPage('customPage')}}},[_vm._v(" "+_vm._s(_vm.$t("vue_label_homepage_custom_homepage_page")))])]):_vm._e()],2)],1),_c('el-popover',{attrs:{"placement":"bottom","min-width":"90","height":"80","trigger":"hover","popper-class":"popoverBox"}},[_c('ul',{staticClass:"extend-menu"},[_c('li',{on:{"click":function($event){return _vm.selectMenuClickEvent('orderAgain')}}},[_vm._v(" "+_vm._s(_vm.$t("c568"))+" ")]),_c('li',{on:{"click":function($event){return _vm.selectMenuClickEvent('addCpn')}}},[_vm._v(" "+_vm._s(_vm.$t("vue_label_norm_addcomponent"))+" ")])]),_c('svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowEditStatusPopover),expression:"isShowEditStatusPopover"}],staticClass:"icon setting-to-edit",attrs:{"slot":"reference","aria-hidden":"true"},slot:"reference"},[_c('use',{attrs:{"href":"#icon-setting"}})])])],1):_vm._e()]),(_vm.isEditStatus)?_c('div',{staticClass:"meter-button"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.cancelSwitchs}},[_vm._v(" "+_vm._s(_vm.$t("button_partner_cancel")))]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.saveEvent}},[_vm._v(" "+_vm._s(_vm.$t("label.save")))])],1):_vm._e(),(_vm.newProfileBtn)?_c('div',{staticClass:"new-profile"},[_c('el-button',{attrs:{"size":"mini"}},[_vm._v(" "+_vm._s(_vm.$t("label.logout")))]),_c('el-button',{attrs:{"size":"mini"}},[_vm._v(" "+_vm._s(_vm.$t("component_setup_tabs_label_delete")))]),_c('el-button',{attrs:{"size":"mini"}},[_vm._v(" "+_vm._s(_vm.$t("c568")))]),_c('el-button',{attrs:{"type":"primary"}},[_vm._v(_vm._s(_vm.$t("vue_label_norm_addcomponent")))])],1):_vm._e()]):_vm._e(),_c('RolePageDel',{attrs:{"rolePageDelTo":_vm.rolePageDelTo,"rolePageObjectData":_vm.rolePageObjectData},on:{"ComponentsDelConfirmRol":_vm.ComponentsDelConfirmRol,"ComponentsDelCancle":_vm.ComponentsDelCancle}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }