<!--
  简档主页组件删除弹框
-->
<template>
  <!-- 删除简档主页 -->
  <el-dialog
    :visible="rolePageDelTo"
    :title="$t('vue_label_homepage_deleteprofilepage')"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :append-to-body='true'	
  >
    <div class="dialog-body">
      <div>
        <p>
          <!--  确认删除简档主页 -->
          {{
            $t("label.ems.confirm") + $t("vue_label_homepage_deleteprofilepage")
          }}
          "{{ rolePageObjectData ? rolePageObjectData.homePageName : "" }}"？
        </p>
        <p>
          <!-- 此项操作不可撤回 -->
          {{ $t("vue_label_norm_undone") }}
        </p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="ComponentsDelCancle">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="ComponentsDelConfirmRol">
        <!-- 确定 -->
        {{ $t("label.ems.confirm") }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    rolePageObjectData: {
      type: Object,
      default: () => {},
    },
    // nameCpn: {
    //   type: String,
    //   default: "",
    // },
    rolePageDelTo: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose() {
      this.$emit("ComponentsDelCancle");
    },
    //取消删除
    ComponentsDelCancle() {
      this.$emit("ComponentsDelCancle");
    },
    //确认删除
    ComponentsDelConfirmRol() {
      this.$emit("ComponentsDelConfirmRol");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 552px;
}
::v-deep .el-dialog__body {
  padding: 20px 0 !important;
}
::v-deep .dialog-body {
  div {
    padding: 0 22px;
    p:first-child {
      font-size: 16px;
      color: #686868;
      height: 20px;
    }
    p:last-child {
      color: #ff3c32;
      font-size: 12px;
    }
  }
}
::v-deep .el-dialog__header {
  padding-top: 8px;
  padding-bottom: 6px;
  padding-right: 26px;
  padding-left: 22px;
  .el-dialog__headerbtn {
    margin-top: -10px;
  }
}
::v-deep .el-dialog__footer {
  padding-top: 10px;
  padding-bottom: 8px;
  padding-right: 26px;
}
</style>
