<template>
  <div class="HeaderCard_div">
    <el-card shadow="always">
      <!-- 简档主页header部分（自定义主页进入时用此表头） -->
      <div v-if="isCoustomPageToRolePage" class="rolePageHeaderCardStyle">
        <div class="rolePageHeaderCardStyle-left">
          <div>
            {{ rolePageObjectData ? rolePageObjectData.homePageName : "" }}
          </div>
          <el-popover
            placement="bottom"
            width="265"
            trigger="click"
            :offset="80"
            v-model="isPopoverShow"
          >
            <div class="operation-area">
              <div class="operation-edit-name">
                <!-- 编辑简档页名称 -->
                {{ $t("vue_label_homepage_editprofilepage") }}
              </div>
              <el-form
                :model="form"
                size="mini"
                label-width="65px"
                label-position="left"
                ref="rolePageHeaderEditPageNameFormRef"
              >
                <!-- 名称 -->
                <!-- 请输入主页名称 -->
                <el-form-item
                  :label="$t('label_chatter_name')"
                  :rules="{
                    required: true,
                    message: $t('vue_label_notice_homepage_pleaceentername'),
                    trigger: ['blur', 'change'],
                  }"
                  prop="name"
                >
                  <el-input v-model="form.name" size="mini"></el-input>
                </el-form-item>
                <el-form-item class="operation-menu">
                  <el-button size="mini" @click="popoverCancleEvent">
                    <!-- 取消 -->
                    {{ $t("button_partner_cancel") }}</el-button
                  >
                  <el-button
                    size="mini"
                    type="primary"
                    @click="
                      saveRolePageHeaderEditPageNameFormEvent(
                        'rolePageHeaderEditPageNameFormRef'
                      )
                    "
                  >
                    <!-- 保存 -->
                    {{ $t("label.save") }}</el-button
                  >
                </el-form-item>
              </el-form>
            </div>

            <i
              class="el-icon-edit rolePageHeaderCard-edit-style"
              slot="reference"
              @click="HeaderBarEditRolePageNameEvent"
            ></i>
          </el-popover>
        </div>
        <!-- 重新排序前 -->
        <div class="rolePageHeaderCardStyle-right" v-if="!isEditStatus">
          <i
            class="el-icon-refresh-right rolePageHeaderCardStyle-right-refresh"
            ref="loadimgbox"
            @click="refreshEvent"
          ></i>
          <el-button size="mini" @click.native="rolePageHeaderCardExitEvent">
            <!-- 退出 -->
            {{ $t("button_partner_back") }}
          </el-button>
          <el-button size="mini" @click.native="rolePageHeaderCardDeleteEvent">
            <!-- 删除 -->
            {{ $t("component_setup_tabs_label_delete") }}</el-button
          >
          <el-button size="mini" @click.native="rolePageHeaderCardReSortEvent">
            <!-- 调整布局 -->
            {{ $t("c568") }}
          </el-button>
          <!-- 添加组件 -->
          <el-button
            size="mini"
            type="primary"
            @click.native="rolePageHeaderCardAddCpnEvent"
            >{{ $t("vue_label_norm_addcomponent") }}</el-button
          >
        </div>
        <!-- 重新排序后 -->
        <div class="rolePageHeaderCardStyle-right" v-if="isEditStatus">
          <el-button size="mini" @click="cancelSwitchs">
            <!-- 取消 -->
            {{ $t("button_partner_cancel") }}</el-button
          >
          <el-button type="primary" size="mini" @click="saveEvent">
            <!-- 保存 -->
            {{ $t("label.save") }}</el-button
          >
        </div>
      </div>
      <!-- 主页部分header：标准主页、个人主页、简档主页 -->
      <!-- && realName 加个判断 接口请求后store存的最新数据才会显示 -->
      <div class="head-menu" v-if="!isCoustomPageToRolePage && realName">
        <div class="head-left-menu">
          <div>{{ this.$store.state.userInfoObj.realName }}</div>
          <div>{{ this.$store.state.userInfoObj.orgName }}</div>
          <div>{{ currentTime }}</div>
          <div
            v-if="
              ['exorg20226495816test', 'exorg20224311927test'].indexOf(
                $CCDK.CCUser.getUserInfo().orgId
              ) == -1
            "
          >
            （{{ $t("label.currency") }}：{{ $store.getters.getCurrencyCode }}）
          </div>
        </div>
        <!-- 右面下拉选择 -->
        <!-- Partnercloud是否为伙伴云用户-->
        <!-- 伙伴云显示右侧下拉菜单 -->
        <!-- v-if="!Partnercloud" -->
        <div class="head-right-menu">
          <!-- 非编辑状态 -->
          <div v-if="!isEditStatus" class="head-right-menu_div">
            <!-- 全屏按钮 -->
            <svg
              class="icon screenfullImg"
              v-show="!changeBlueYes"
              @mouseover="overChange"
              aria-hidden="true"
            >
              <use href="#icon-fullScreenWhite"></use>
            </svg>
            <svg
              class="icon screenfullImg"
              @click="screenFull"
              v-show="changeBlueYes"
              @mouseout="outChange"
              aria-hidden="true"
            >
              <use href="#icon-fullScreenBlue"></use>
            </svg>
            <!-- 刷新按钮 -->
            <i
              ref="loadimgbox"
              class="el-icon-refresh-right refresh-class"
              @click="refreshEvent"
            ></i>
            <el-dropdown trigger="click" class="dropdown-area">
              <span class="el-dropdown-link">
                {{ selectTitleName }}
                <i class="el-icon-caret-bottom"></i>
              </span>
              <!-- 下拉选项 -->
              <el-dropdown-menu
                placement="bottom-end"
                slot="dropdown"
                style="border: 1px solid #e1e1e1 !important"
              >
                <!-- 标准主页 -->
                <el-dropdown-item
                  v-for="(item, index) in HomePageListData"
                  :key="index"
                >
                  <a
                    @click.prevent="clickToPage('standardPage')"
                    :href="clickToPageHref('standardPage')"
                    target="_blank"
                    v-if="item.type == 'standard'"
                    :class="{
                      'custom-menu-style': selectTitleName === item.name,
                    }"
                  >
                    {{ item.name }}
                  </a>
                </el-dropdown-item>
                <!-- 个人主页 -->
                <el-dropdown-item>
                  <!-- :class="{
                      'custom-menu-style':
                        selectTitleName ===
                        `${this.$store.state.userInfoObj.realName}${$t(
                          'vue_label_homepage_owner'
                        )}`,
                    }" -->
                  <a
                    :href="clickToPageHref('personalPage')"
                    @click.prevent="clickToPage('personalPage')"
                    target="_blank"
                    :class="{
                      'custom-menu-style':
                        selectTitleName === `${$t('vue_label_homepage_owner')}`,
                    }"
                  >
                    <!-- {{ this.$store.state.userInfoObj.realName }} -->
                    {{ $t("vue_label_homepage_owner") }}
                  </a>
                  <!-- 的主页 -->
                </el-dropdown-item>
                <!-- 简档主页 -->
                <el-dropdown-item v-if="rolePageDropDownName">
                  <a
                    :href="clickToPageHref('rolePage')"
                    @click.prevent="clickToPage('rolePage')"
                    target="_blank"
                    :class="{
                      'custom-menu-style':
                        selectTitleName === rolePageDropDownName,
                    }"
                  >
                    {{ rolePageDropDownName }}</a
                  >
                </el-dropdown-item>
                <!-- 自定义主页 -->
                <el-dropdown-item v-if="isAdmin && !Partnercloud">
                  <a
                    :href="clickToPageHref('customPage')"
                    @click.prevent="clickToPage('customPage')"
                    target="_blank"
                    :class="{
                      'custom-menu-style':
                        selectTitleName ===
                        $t('vue_label_homepage_custom_homepage_page'),
                    }"
                  >
                    {{ $t("vue_label_homepage_custom_homepage_page") }}</a
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- 点击进入编辑状态按钮 -->
            <el-popover
              placement="bottom"
              min-width="90"
              height="80"
              trigger="hover"
              popper-class="popoverBox"
            >
              <ul class="extend-menu">
                <li @click="selectMenuClickEvent('orderAgain')">
                  <!-- 重新排序 -->
                  {{ $t("c568") }}
                </li>
                <!-- 添加组件 -->
                <li @click="selectMenuClickEvent('addCpn')">
                  {{ $t("vue_label_norm_addcomponent") }}
                </li>
              </ul>
              <svg
                class="icon setting-to-edit"
                aria-hidden="true"
                v-show="isShowEditStatusPopover"
                slot="reference"
              >
                <use href="#icon-setting"></use>
              </svg>
            </el-popover>
          </div>
        </div>
        <!-- 个人主页编辑状态的显示 -->
        <div class="meter-button" v-if="isEditStatus">
          <el-button size="mini" @click="cancelSwitchs">
            <!-- 取消 -->
            {{ $t("button_partner_cancel") }}</el-button
          >
          <el-button type="primary" size="mini" @click="saveEvent">
            <!-- 保存 -->
            {{ $t("label.save") }}</el-button
          >
        </div>
        <!-- 新建简档主页弹框点确定按钮跳转表头编辑状态的显示 -->
        <div class="new-profile" v-if="newProfileBtn">
          <el-button size="mini">
            <!-- 退出 -->
            {{ $t("label.logout") }}</el-button
          >
          <el-button size="mini">
            <!-- 删除 -->
            {{ $t("component_setup_tabs_label_delete") }}</el-button
          >
          <el-button size="mini">
            <!-- 重新排序 -->
            {{ $t("c568") }}</el-button
          >
          <!-- 添加组件 -->
          <el-button type="primary">{{
            $t("vue_label_norm_addcomponent")
          }}</el-button>
        </div>
      </div>
      <RolePageDel
        :rolePageDelTo="rolePageDelTo"
        :rolePageObjectData="rolePageObjectData"
        @ComponentsDelConfirmRol="ComponentsDelConfirmRol"
        @ComponentsDelCancle="ComponentsDelCancle"
      />
    </el-card>
  </div>
</template>

<script>
// import * as request from "./api.js";
import RolePageDel from "./RolePageDel";

export default {
  components: {
    RolePageDel,
  },
  props: {
    // userName: {
    //   type: String,
    //   default: "",
    // },
    // companyName: {
    //   type: String,
    //   default: "",
    // },
    selectTitleName: {
      type: String,
      default: "",
    },
    HomePageListData: {
      type: Array,
      default: () => [],
    },
    // 是否是个人主页编辑状态
    isEditStatus: {
      type: Boolean,
      default: false,
    },

    // 是否显示简档主页编辑状态
    newProfileBtn: {
      type: Boolean,
      default: false,
    },
    // 是否是自定义页面进入的简档主页，在简档主页时使用
    isCoustomPageToRolePage: {
      type: Boolean,
      default: false,
    },
    // 页面加载来时的简档主页的name,id,type对象
    rolePageObjectData: {
      type: Object,
      default: () => {},
    },
    //  简档主页名称
    rolePageDropDownName: {
      type: String,
      default: "",
    },
    // rolePageDropDownNameS: {
    //   type: String,
    //   default: "",
    // },
    /**
     * 是否是管理员
     * 是管理员才显示自定义页面
     */
    // isAdmin: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      changeBlueYes: false,
      //是否为伙伴云用户
      Partnercloud: this.$store.state.userInfoObj.ispartner,
      // fullShow: false,
      form: {
        name: "",
      },
      // 泡泡是否显示
      isPopoverShow: false,
      // orgName: "", //组织名称
      realName:JSON.parse(localStorage.getItem("userInformation")).data.realName, //用户的名字
      rolePageDelTo: false,
      currentTime: null, //当前时区时间
      isShowEditStatusPopover: false, // 是否显示个人主页的重新排序气泡菜单
    };
  },
  created() {
    this.getUserInfo();
    // 是否显示个人主页的重新排序气泡菜单
    //  this.$i18n.t("vue_label_homepage_owner")的主页
    this.$nextTick(() => {
      // this.selectTitleName ===
      // this.$store.state.userInfoObj.realName +
      //   this.$i18n.t("vue_label_homepage_owner")
      //   ? (this.isShowEditStatusPopover = true)
      //   : (this.isShowEditStatusPopover = false);
      this.selectTitleName === this.$i18n.t("vue_label_homepage_owner")
        ? (this.isShowEditStatusPopover = true)
        : (this.isShowEditStatusPopover = false);
    });
  },
  methods: {
    overChange() {
      this.changeBlueYes = true;
    },
    outChange() {
      this.changeBlueYes = false;
    },
    //点击全屏按钮
    screenFull() {
      this.$emit("screenFull");
      setTimeout(() => {
        this.$bus.$emit("letScreenSuccess");
      }, 0);
    },
    clickToPageHref(pageUrl) {
      return `#/homePageObject/${pageUrl}`;
    },
    // 格式化当前时区时间
    currentTimefun(timeZone) {
      clearInterval(timer);
      let that = this;
      function timerFun() {
        that.currentTime = that.$formatZoneTime(
          Date.parse(new Date()),
          timeZone
        );
      }
      timerFun();
      var timer = setInterval(timerFun, 1000);
    },
    // 获取用户名称、组织名称
    getUserInfo() {
      // 发送用户名称到个人index主页，再传回来达到修改selectTitleName名称
      this.$emit("sendRealName", this.$store.state.userInfoObj.realName);

      // 获取当前时区函数（如果app中请求到用户信息）
      this.currentTimefun(this.$store.state.userInfoObj.timeZone);
    },

    // 取消泡泡显示事件
    popoverCancleEvent() {
      this.isPopoverShow = false;
    },
    // 刷新
    refreshEvent() {
      this.$refs.loadimgbox.classList.add("loadimg");
      this.$emit("refreshEvent");
      setTimeout(() => {
        this.$refs.loadimgbox.classList.remove("loadimg");
      }, 1000);
    },
    // 点击跳转到页面
    clickToPage(pageUrl) {
      this.$router.push(`/homePageObject/${pageUrl}`);
      // 发送到index（）
      this.$cookies.set("clickToPage", pageUrl, { sameSite: 'Strict' });
    },

    // 个人主页点击取消
    cancelSwitchs() {
      this.$emit("cancelSwitchs");
    },
    // 右侧下拉框重新排序和添加组件事件发射
    selectMenuClickEvent(eventType) {
      this.$emit("selectMenuClickEvent", eventType);
    },
    // 保存事件
    saveEvent() {
      this.$emit("saveEvent");
    },

    /**
     * 从自定义主页进来时的简档主页头部发射事件部分
     */
    // 退出
    rolePageHeaderCardExitEvent() {
      this.$emit("rolePageHeaderCardExitEvent");
    },
    // 删除
    rolePageHeaderCardDeleteEvent() {
      this.rolePageDelTo = true;
    },
    ComponentsDelCancle() {
      this.rolePageDelTo = false;
    },
    //
    //简档主页确认删除
    ComponentsDelConfirmRol() {
      this.$emit("ComponentsDelConfirmRol");
    },
    // 重新排序
    rolePageHeaderCardReSortEvent() {
      this.$emit("rolePageHeaderCardReSortEvent");
    },
    // 添加组件
    rolePageHeaderCardAddCpnEvent() {
      this.$emit("rolePageHeaderCardAddCpnEvent");
    },
    // 管理员权限的header修改简档主页名称事件
    HeaderBarEditRolePageNameEvent() {
      // 点击编辑时,回显已有name
      this.form.name = this.rolePageObjectData.homePageName;
      this.$emit("HeaderBarEditRolePageNameEvent", this.rolePageObjectData);
    },
    saveRolePageHeaderEditPageNameFormEvent(rolePageHeaderEditPageNameFormRef) {
      this.$refs[rolePageHeaderEditPageNameFormRef].validate((valid) => {
        if (valid) {
          this.isPopoverShow = false;
          this.$emit("saveRolePageHeaderEditPageNameFormEvent", this.form.name);
        } else {
          return false;
        }
      });
    },
  },
  computed: {
    // 是否是管理员判断
    isAdmin() {
      // 如果profileId===aaa000001则是管理员
      return this.$store.state.userInfoObj.profileId === "aaa000001"
        ? true
        : false;
    },
  },
};
</script>

<style scoped lang="scss">
.HeaderCard_div {
  background-color: #f0f0f0;
  position: sticky;
  top: 0px;
  left: 10px;
  right: 20px;
  z-index: 100;
  .el-card {
    // margin-top: 10px;
  }
}
a {
  color: #606266;
  display: inline-block;
  width: 100%;
}
// 刷新旋转
@keyframes loadimg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadimg {
  animation: loadimg 1s linear infinite;
  // animation: loadimg 2s linear infinite;
}

.el-card {
  user-select: none;
  line-height: 50px;
  padding: 0 20px;
  margin-bottom: 10px;
  ::v-deep .el-card__body {
    padding: 0;
  }
}
.custom-menu-style {
  color: #2d6cfc !important;
  // background-color: #f3f7ff;
}
/* 鼠标放置颜色 */
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #f5f5f5;
  color: #000;
}
/* 简档主页部分样式 */
.rolePageHeaderCardStyle {
  display: flex;
  justify-content: space-between;
}
.rolePageHeaderCardStyle-left {
  display: flex;
  align-items: center;
  > div {
    margin-right: 10px;
    cursor: pointer;
  }
}
.rolePageHeaderCardStyle-right {
  display: flex;
  align-items: center;
  .rolePageHeaderCardStyle-right-refresh {
    margin-right: 10px;
    cursor: pointer;
  }
}

/* 非简档主页部分 */
.head-menu {
  display: flex;
  justify-content: space-between;
}
.head-left-menu {
  display: flex;
  div {
    margin: 0 10px;
  }
}
/* 右面设置板块 */
.head-right-menu {
  .head-right-menu_div {
    display: flex;
    align-items: center;
    .el-dropdown-link {
      user-select: none;
    }
    .screenfullImg {
      width: 15px;
      height: 15px;
      cursor: pointer;
      vertical-align: -5px;
      margin-right: 6px;
    }
    // .el-icon-rank {
    //   font-size: 18px;
    //   transform: rotate(45deg);
    //   color: #777;
    //   cursor: pointer;
    //   vertical-align: -2px;
    // }
    .refresh-class {
      cursor: pointer;
      margin: 0 6px; //现
      // margin-right: 6px;//原
      // transform: scale(0.9);
    }
    .dropdown-area {
      cursor: pointer;
      margin-left: 6px;
    }
    .setting-to-edit {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.extend-menu {
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  margin: 0;
  li {
    padding: 5px;
  }
  li:hover {
    color: #fff;
    background-color: #006dcc;
  }
}

.operation-area {
  font-size: 12px;
  .operation-edit-name {
    margin-bottom: 10px;
  }
  .operation-menu {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .el-button:last-child {
      margin-right: 5px;
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 0;
  ::v-deep .el-form-item__label {
    min-width: 56px !important;
  }
  .el-form-item__content {
    .el-input {
      width: 96% !important;
    }
  }
}
.rolePageHeaderCard-edit-style {
  cursor: pointer;
}
</style>

<style lang="scss" >
.el-popover.colPopover {
  border: 1px solid #e1e1e1 !important;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.el-popover {
  min-width: 70px;
}
.popoverBox {
  padding: 6px;
}
/* 设置右面的泡泡不跑出右滑块，不要启用会有bug */
/* .el-popper {
  position: absolute;
  left:auto!important;
  right: 26px!important;
  padding: 5px 0;
  box-sizing: border-box;
}
.popper__arrow {
  top: 1;
  margin-left:36px;
} */
</style>
